<messages>["./Zone"]</messages>

<template>
  <div>
    <base-layout mw3>
      <v-col cols="12">
        <v-alert
          v-model="isSuccessAlertVisible"
          type="success"
          dismissible>
          <i18n path="createUpdate.success">
            <template #link>
              <router-link
                class="success-alert-link"
                :to="{ name: 'zone.view',
                       params: { name: successData} }"
                v-text="successData"/>
            </template>
          </i18n>
        </v-alert>
      </v-col>
    </base-layout>

    <v-alert
      id="ivalidDataAlert"
      type="error"
      :value="isInvalidDataAlertVisible">
      {{
        formErrors
          ? $t ('general.invalid.form')
          : $t ('createUpdate.error')
      }}
    </v-alert>

    <zone-create-update
      is-create
      @dataValidityChanged="dataValidityChanged"
      @success="success"
      @failure="failure"/>
  </div>
</template>

<script>
  import goTo from 'vuetify/lib/services/goto'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import ZoneCreateUpdate from './components/ZoneCreateUpdate'
  import {mapMutations} from 'vuex'

  export default {
    name: 'ZoneCreate',

    components: {
      BaseLayout,
      ZoneCreateUpdate
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        successData: null,
        isInvalidDataAlertVisible: false,
        formErrors: false
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      success (e) {
        this.successData = e
        this.addEvent ({
          type: 'success',
          message: this.$t ('createUpdate.success', {link: e}),
          objects: [{
            name: e,
            link: {
              name: 'zone.view',
              params: {name: e}
            }
          }]
        })
        this.isSuccessAlertVisible = true
      },

      failure () {
        this.successData = null
        this.isSuccessAlertVisible = false
        this.formErrors = false
        this.isInvalidDataAlertVisible = true
        this.scrollToAlert ()
      },

      dataValidityChanged (e) {
        this.formErrors = !e
        this.isInvalidDataAlertVisible = !e
        if (this.isInvalidDataAlertVisible) this.scrollToAlert ()
      },

      scrollToAlert () {
        goTo (0)
      }
    }
  }
</script>
